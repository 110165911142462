import React, { Component } from 'react';
import '../style/main.css';
import { withRouter, Route, Link } from 'react-router-dom';
import RankHero from '../static/nacssa_championship.jpg';
import ni_logo from '../static/ninja_intensity_logo.png';
import rock_solid_logo from '../static/rock_solid_logo.png';
import obstacle_academy_logo from '../static/obstacle_academy.png';
import jungle_gym_logo from '../static/jungle_gym.png';
import ferox_athletics_logo from '../static/ferox_athletics.png';
import motive_movement_logo from '../static/motive_movement.png';
import rise_warehouse_logo from '../static/rise_warehouse.png';
import stamford_ninja_logo from '../static/stamford_ninja.png';
import big_time_ninja_logo from '../static/big_time_ninja.png';
import life_force_logo from '../static/life_force.png';
import gravity_forge_logo from '../static/gravity_forge.png';


class Registration extends Component { 

    constructor(props) {
        super(props);
      }

    state={
        menuOpen: false,
        toggleDropdown: false,
        image: RankHero
    }         

    componentDidMount(){

    }

    render() {
        return (
            <div>

<div className="registerCard">
                                    <div className="flex-row" style={{ justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                                        <div className="flex-row" style={{alignItems: 'center'}}>
                                            <div><img src={rock_solid_logo} width="150px"></img></div>
                                            <div className="cardTitle">
                                            <div className="gymName" style={{marginBottom: "5px", fontSize: "20px"}}>NSC Youth Championship</div>
                                                <div className="gymInfo" style={{fontSize: "16px"}}>Rock Solid Warrior</div>
                                                <div className="gymInfo" style={{fontSize: "16px"}}>South Apex NC</div>
                                                <div className="gymInfo" style={{fontSize: "16px"}}>Aug 31 & Sep 1, 2024</div>
                                            </div>
                                        </div>
                                        {/* <div><span className="gymInfo priceCell" style={{ fontSize: "48px" }}>$100</span><a className="registerButton" href="https://ninjamasterapp.com/events/5166-nsc-youth-championship-ii-rock-solid-warrior" target="_blank">Register</a></div> */}
                                        <div><span className="gymInfo priceCell" style={{ fontSize: "48px" }}>$100</span><a className="registerButtonRed" href="https://docs.google.com/forms/d/e/1FAIpQLSezCUWAQu8r0XNPMMX6xSp-doikWsOWueSAJR9f4nRdyD2-DA/viewform?usp=sf_link" target="_blank">Waitlist</a></div>
                                        {/* <div><span className="gymInfo priceCell" style={{ fontSize: "48px" }}>$100</span><span className="registerButtonGrey">Coming Soon</span></div> */}
                                    </div>
                                </div>


                <div className="rulesContainer">
                <div className="NSCTitle" style={{fontSize: "20px"}}>NSC Pro Waitlist Procedure</div>
                    <p>In the event a competition is sold out, click the waitlist button below to register for the waitlist. NSC reserves a certain number of spots for each competition to ensure top athletes can register, additional spots will be given to athletes via the waitlist in order of athlete's NSC ranking.</p>
                    </div>
                                

                  <div className="registerCard">
                    <div className="flex-row" style={{justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
                        <div className="flex-row">
                            <div style={{alignSelf: "center"}}><img src={obstacle_academy_logo} width="150px"></img></div>
                            <div className="cardTitle">
                                <div className="gymName">Obstacle Academy</div>
                                <div className="gymInfo">Eden Prairie, Minnesota</div>
                                <div className="gymInfo">November 9 & 10, 2024</div>
                            </div>
                        </div>
                        {/* <div><span className="gymInfo priceCell" style={{fontSize: "48px"}}>$100</span><a  className="registerButton" href="https://ninjamasterapp.com/events/5226-nsc4-qualifier-1-obstacle-academy" target="_blank">Register</a></div> */}
                        <div><span className="gymInfo priceCell" style={{ fontSize: "48px" }}>$100</span><a className="registerButtonRed" href="https://ninjamasterapp.com/events/5226-nsc4-qualifier-1-obstacle-academy" target="_blank">Waitlist</a></div>
                        {/* <div><span className="gymInfo priceCell" style={{ fontSize: "48px" }}>$100</span><span className="registerButtonGrey">Coming Soon</span></div> */}
                    </div>
                </div>

                <div className="registerCard">
                    <div className="flex-row" style={{justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
                        <div className="flex-row">
                            <div style={{alignSelf: "center"}}><img src={stamford_ninja_logo} width="150px"></img></div>
                            <div className="cardTitle">
                                <div className="gymName">Stamford Ninja Academy</div>
                                <div className="gymInfo">Stamford, Connecticut</div>
                                <div className="gymInfo">December 7 & 8, 2024</div>
                            </div>
                        </div>
                        {/* <div><span className="gymInfo priceCell" style={{fontSize: "48px"}}>$100</span><a  className="registerButton" href="https://ninjamasterapp.com/events/5228-nsc4-qualifier-2-stamford-ninja-academy" target="_blank">Register</a></div> */}
                        <div><span className="gymInfo priceCell" style={{ fontSize: "48px" }}>$100</span><a className="registerButtonRed" href="https://ninjamasterapp.com/events/5228-nsc4-qualifier-2-stamford-ninja-academy" target="_blank">Waitlist</a></div>
                        {/* <div><span className="gymInfo priceCell" style={{ fontSize: "48px" }}>$100</span><span className="registerButtonGrey">Coming Soon</span></div> */}
                    </div>
                </div>

                <div className="registerCard">
                    <div className="flex-row" style={{justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
                        <div className="flex-row">
                            <div style={{alignSelf: "center"}}><img src={jungle_gym_logo} width="150px"></img></div>
                            <div className="cardTitle">
                                <div className="gymName">Jungle Gym</div>
                                <div className="gymInfo">Pinellas Park, Florida</div>
                                <div className="gymInfo">December 14 & 15, 2024</div>
                            </div>
                        </div>
                        {/* <div><span className="gymInfo priceCell" style={{fontSize: "48px"}}>$100</span><a  className="registerButton" href="https://ninjamasterapp.com/events/5229-nsc4-qualifier-3-jungle-gym" target="_blank">Register</a></div> */}
                        <div><span className="gymInfo priceCell" style={{ fontSize: "48px" }}>$100</span><a className="registerButtonRed" href="https://ninjamasterapp.com/events/5229-nsc4-qualifier-3-jungle-gym" target="_blank">Waitlist</a></div>
                        {/* <div><span className="gymInfo priceCell" style={{ fontSize: "48px" }}>$100</span><span className="registerButtonGrey">Coming Soon</span></div> */}
                        
                    </div>
                </div>

                
                <div className="registerCard">
                    <div className="flex-row" style={{justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
                        <div className="flex-row">
                            <div style={{alignSelf: "center"}}><img src={gravity_forge_logo} width="150px"></img></div>
                            <div className="cardTitle">
                                <div className="gymName">The Gravity Forge</div>
                                <div className="gymInfo">Hatboro, Pennsylvania</div>
                                <div className="gymInfo">January 4 & 5, 2025</div>
                            </div>
                        </div>
                        {/* <div><span className="gymInfo priceCell" style={{fontSize: "48px"}}>$100</span><a  className="registerButton" href="https://ninjamasterapp.com/events/5230-nsc4-qualifier-4-the-gravity-forge" target="_blank">Register</a></div> */}
                        <div><span className="gymInfo priceCell" style={{ fontSize: "48px" }}>$100</span><a className="registerButtonRed" href="https://ninjamasterapp.com/events/5230-nsc4-qualifier-4-the-gravity-forge" target="_blank">Waitlist</a></div>
                        {/* <div><span className="gymInfo priceCell" style={{ fontSize: "48px" }}>$100</span><span className="registerButtonGrey">Coming Soon</span></div> */}
                    </div>
                </div>

                <div className="registerCard">
                    <div className="flex-row" style={{justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
                        <div className="flex-row">
                            <div><img src={rise_warehouse_logo} width="150px"></img></div>
                            <div className="cardTitle">
                                <div className="gymName">Rise at the Warehouse</div>
                                <div className="gymInfo">Houston, Texas</div>
                                <div className="gymInfo">January 18 & 19, 2025</div>
                            </div>
                        </div>
                        {/* <div><span className="gymInfo priceCell" style={{fontSize: "48px"}}>$100</span><a  className="registerButton" href="https://ninjamasterapp.com/events/5231-nsc4-qualifier-5-rise-at-the-warehouse" target="_blank">Register</a></div> */}
                        <div><span className="gymInfo priceCell" style={{ fontSize: "48px" }}>$100</span><a className="registerButtonRed" href="https://ninjamasterapp.com/events/5231-nsc4-qualifier-5-rise-at-the-warehouse" target="_blank">Waitlist</a></div>
                        {/* <div><span className="gymInfo priceCell" style={{ fontSize: "48px" }}>$100</span><span className="registerButtonGrey">Coming Soon</span></div> */}
                    </div>
                </div>

                                <div className="registerCard">
                    <div className="flex-row" style={{justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
                        <div className="flex-row">
                            <div><img src={motive_movement_logo} width="150px"></img></div>
                            <div className="cardTitle">
                                <div className="gymName">Motive Movement</div>
                                <div className="gymInfo">Greenville, South Carolina</div>
                                <div className="gymInfo">February 1 & 2, 2025</div>
                            </div>
                        </div>
                        {/* <div><span className="gymInfo priceCell" style={{fontSize: "48px"}}>$100</span><a  className="registerButton" href="https://ninjamasterapp.com/events/5232-nsc4-qualifier-6-motive-movement" target="_blank">Register</a></div> */}
                        <div><span className="gymInfo priceCell" style={{ fontSize: "48px" }}>$100</span><a className="registerButtonRed" href="https://ninjamasterapp.com/events/5232-nsc4-qualifier-6-motive-movement" target="_blank">Waitlist</a></div>
                        {/* <div><span className="gymInfo priceCell" style={{ fontSize: "48px" }}>$100</span><span className="registerButtonGrey">Coming Soon</span></div> */}
                    </div>
                </div>


                                <div className="registerCard">
                                    <div className="flex-row" style={{ justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                                        <div className="flex-row">
                                            <div style={{backgroundColor: "rgb(60,60,60)"}}><img src={life_force_logo} width="150px"></img></div>
                                            <div className="cardTitle">
                                                <div className="gymName">Life Force Ninja</div>
                                                <div className="gymInfo">Bellingham, Washington</div>
                                                <div className="gymInfo">February 15 & 16, 2025</div>
                                            </div>
                                        </div>
                                        {/* <div><span className="gymInfo priceCell" style={{ fontSize: "48px" }}>$100</span><a className="registerButton" href="https://ninjamasterapp.com/events/5233-nsc4-qualifier-7-life-force-ninja" target="_blank">Register</a></div> */}
                                        <div><span className="gymInfo priceCell" style={{ fontSize: "48px" }}>$100</span><a className="registerButtonRed" href="https://ninjamasterapp.com/events/5233-nsc4-qualifier-7-life-force-ninja" target="_blank">Waitlist</a></div>
                                        {/* <div><span className="gymInfo priceCell" style={{ fontSize: "48px" }}>$100</span><span className="registerButtonGrey">Coming Soon</span></div> */}
                                    </div>
                                </div>

                                <div className="registerCard">
                                    <div className="flex-row" style={{ justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                                        <div className="flex-row">
                                            <div><img src={big_time_ninja_logo} width="150px"></img></div>
                                            <div className="cardTitle">
                                                <div className="gymName">Big Time Ninja</div>
                                                <div className="gymInfo">Bolingbrook, Illinois</div>
                                                <div className="gymInfo">March 1 & 2, 2025</div>
                                            </div>
                                        </div>
                                        {/* <div><span className="gymInfo priceCell" style={{ fontSize: "48px" }}>$100</span><a className="registerButton" href="https://ninjamasterapp.com/events/5234-nsc4-qualifier-8-big-time-ninja" target="_blank">Register</a></div> */}
                                        <div><span className="gymInfo priceCell" style={{ fontSize: "48px" }}>$100</span><a className="registerButtonRed" href="https://ninjamasterapp.com/events/5234-nsc4-qualifier-8-big-time-ninja" target="_blank">Waitlist</a></div>
                                        {/* <div><span className="gymInfo priceCell" style={{ fontSize: "48px" }}>$100</span><span className="registerButtonGrey">Coming Soon</span></div> */}
                                    </div>
                                </div>

                                <div className="registerCard">
                    <div className="flex-row" style={{justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"}}>
                        <div className="flex-row">
                            <div><img src={rock_solid_logo} width="150px"></img></div>
                            <div className="cardTitle">
                                <div className="gymName">Rock Solid Warrior</div>
                                <div className="gymInfo">NSC Season IV Championship</div>
                                <div className="gymInfo">South Apex, North Carolina</div>
                                <div className="gymInfo">March 22 & 23, 2025</div>
                            </div>
                        </div>
                        
                        <div>
                            <span className="gymInfo priceCell" style={{ fontSize: "48px" }}>$100</span>
                            <span className="registerButtonGrey">Qualification Only</span>
                        </div>
                    </div>
                </div>
                    

            </div>
        );
    }
}


export default (withRouter(Registration));
