import React, { Component } from 'react';
import '../style/main.css';
import NSNHeader from "../components/header";
import HeroImg from "../components/HeroImg";
import NSNFooter from "../components/footer";
import Registration from "../components/Registration";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import '../style/ath_bio.css';
import queryString from 'query-string';
import { getAthleteBio } from '../api/api';




library.add(faUser, faCaretDown, faCaretUp);

class AthleteBio extends Component {

    state = {
        comp_id: '',
        athlete_id: '',
        athlete_arr: [],
        all_comps: [],
        stats: {}
    }

    componentDidMount() {
        console.log(this);
        var queryObj = queryString.parse(this.props.location.search);
        // var liveCheck = false; Add this in if ever want to add websocket to this page
        // if (queryObj.live_clock) {
        // liveCheck = queryObj.live_clock;
        // }
        // var query_comp = queryObj.comp_id || '';
        var query_athlete_id = queryObj.athlete_id || '';
        getAthleteBio(query_athlete_id).then(resp => {
            console.log(resp);
            this.setState({ athlete_id: query_athlete_id, athlete_arr: resp.athlete, all_comps: resp.all_comps, stats: resp.stats[0] });
        });
    }

    render() {
        return (
            <div className="mainDiv">
                <NSNHeader title='Ninja Sport Network' link='' linkTitle=''></NSNHeader>


                <div className="athSummaryContainer">
                    {this.state.athlete_arr.length > 0 ?
                        <div className="athFlexCont">

                            <div className="athCard" style={{backgroundImage: "url('https://nsn-assets.s3.us-east-2.amazonaws.com/images/athletes/vertical_images/" + this.state.athlete_arr[0].first_name + '_' + this.state.athlete_arr[0].last_name + ".jpg'), url('https://nsn-assets.s3.us-east-2.amazonaws.com/images/athletes/vertical_images/NSC.jpg')"}}>
                            {/* <div className="athCard"> */}
                                {/* Athlete Card Placeholder */}
                                {/* <img className="" src="https://nsn-assets.s3.us-east-2.amazonaws.com/images/athletes/vertical_images/Tyler_Smith.jpg" alt="actionPhoto" width="auto" height="auto"/> */}
                            </div>

                            <div className="athInfo">
                                <div className="athName">{this.state.athlete_arr[0].first_name + ' ' + this.state.athlete_arr[0].last_name} </div>
                                <div className="athSubInfo">NSR Rank: {this.state.stats.power_rank}</div>
                                <div className="athSubInfo">Age:</div>
                                <div className="athSubInfo">Hometown:</div>
                                <div className="athSubInfo">NSC Seasons: {this.state.stats.season_count}</div>
                                <div className="athSubInfo">Gym: {this.state.athlete_arr[0].home_gym}</div>
                                <div className="athSubInfo">Championship App.: {this.state.stats.champ_app}</div>
                                <div className="athSubInfo">Championship Finals App.: {this.state.stats.champ_final_app}</div>
                            </div>

                        </div>
                        : null}
                </div>

                <div className="athBioFlexCnt">
                    <div className="athSummaryContainer fixedSize">
                        <div className="athName">Open Stats</div>
                        <div className="statSection">
                            <div>Comps Competed: {this.state.stats.open_comps}</div>
                            <div>Open Clears: {this.state.stats.open_clears}</div>
                            <div>Open Full Clears: {this.state.stats.open_full_clears}</div>
                        </div>
                    </div>

                    <div className="athSummaryContainer fixedSize">
                        <div className="athName">Finals Stats</div>
                        <div className="statSection">
                            <div>Finals Appearance: {this.state.stats.finals_app} - {Math.round((this.state.stats.finals_app / this.state.stats.open_comps) * 100)}%</div>
                            <div>Hybrid Clears: {this.state.stats.hybrid_clears}</div>
                            <div>Hybrid Full Clears: {this.state.stats.hybrid_full_clears}</div>
                            <div>Burnout Full Clears: {this.state.stats.burnout_full_clears}</div>
                        </div>
                    </div>

                    <div className="athSummaryContainer fixedSize">
                        <div className="athName">Podium Stats</div>
                        <div className="statSection">
                            <div>Podiums: {this.state.stats.podiums}</div>
                            <div>1st Places: {this.state.stats.first_count}</div>
                            <div>2nd Places: {this.state.stats.second_count}</div>
                            <div>3rd Places: {this.state.stats.third_count}</div>
                        </div>
                    </div>

                   

                </div>

                <div className="athSummaryContainer">
                    <div className="athName">Competition History</div>
                    <div className="statTableDiv">
                        <table className="statTable">
                            <th>Season</th>
                            <th>Gym</th>
                            <th>Overall</th>
                            <th>Hybrid</th>
                            <th>Speed</th>
                            <th>Burnout</th>
                            <th>Open</th>
                            <th>Points</th>

                            <thead></thead>

                            {this.state.all_comps.map((item, index) => {
                                return <tr>
                                    <td>{item.season_id}</td>
                                    <td>{item.gym_name}</td>
                                    <td>{item.athlete_rank}</td>
                                    <td>{item.hybrid_rank}</td>
                                    <td>{item.speed_rank}</td>
                                    <td>{item.burnout_rank}</td>
                                    <td>{item.open_rank}</td>
                                    <td>{item.points}</td>
                                </tr>
                            })}
                        </table>
                    </div>

                </div>



                <NSNFooter title='' link='' linkTitle=''></NSNFooter>
            </div>
        );
    }
}


export default AthleteBio;